import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMarketingIntegrationsContext } from "./MarketingIntegrationsProvider";

const TiktokPixel = () => {
  const location = useLocation();
  const { tiktokPixelId } = useMarketingIntegrationsContext();
  const initializedPixels = useRef(new Set());

  useEffect(() => {
    // Inject Facebook Pixel script dynamically if not already present
    injectTiktokPixelScript();
  }, []);

  useEffect(() => {
    if (!tiktokPixelId) return;

    initializeTiktokPixel(tiktokPixelId);
  }, [tiktokPixelId]);

  useEffect(() => {
    if (!tiktokPixelId) return;

    trackPageView(tiktokPixelId, { content_name: location.pathname });
  }, [location.pathname, tiktokPixelId]);

  // Function to inject Tiktok Pixel script into the document head
  const injectTiktokPixelScript = () => {
    if (document.getElementById("tiktok-pixel")) return;
    const script = document.createElement("script");
    script.id = "tiktok-pixel";
    script.innerHTML = `
        !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
        var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
        ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
        }(window, document, 'ttq');
    `;

    document.head.appendChild(script);
    console.log("Tiktok Pixel injected");
  };

  // Function to initialize a Tiktok Pixel with specific configurations
  const initializeTiktokPixel = id => {
    if (!id || !window.ttq) return;

    if (initializedPixels.current.has(id)) {
      console.log("Tiktok Pixel ID already initialized:", id);
      return;
    }
    window.ttq.load(id);
    // window.ttq.page();
    initializedPixels.current.add(id);

    console.log("Tiktok Pixel initialized with ID:", id);
  };

  // Function to track a PageView event with custom parameters
  const trackPageView = (id, params) => {
    if (!id || !window.ttq) return;

    window.ttq.instance(id).track("PageView(Filtered)", params);
    console.log(
      "PageView event sent to Tiktok Pixel ID:",
      id,
      "with params:",
      params
    );
  };

  return null;
};

export default TiktokPixel;
