import GoogleAnalytics from "./GoogleAnalytics";
import MetaPixel from "./MetaPixel";
import GoogleTagManager from "./GoogleTagManager";
import React from "react";
import TiktokPixel from "./TiktokPixel";
import { MarketingIntegrationsProvider } from "./MarketingIntegrationsProvider";

const MarketingIntegrations = () => {
    return (
        <MarketingIntegrationsProvider>
            <>
                <GoogleAnalytics />
                <MetaPixel />
                <GoogleTagManager />
                <TiktokPixel />
            </>
        </MarketingIntegrationsProvider>
    );
};

export default MarketingIntegrations;
