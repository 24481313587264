import React, { createContext, useContext, useState, useEffect } from "react";
import useMarketingIntegrations from "./useMarketingIntegrations";

const MarketingIntegrationsContext = createContext();

export const MarketingIntegrationsProvider = ({ children }) => {
    const [integrations, setIntegrations] = useState({});
    const marketingIntegrations = useMarketingIntegrations();

    useEffect(() => {
        setIntegrations(marketingIntegrations);
    }, [marketingIntegrations]);

    return (
        <MarketingIntegrationsContext.Provider value={integrations}>
            {children}
        </MarketingIntegrationsContext.Provider>
    );
};

export const useMarketingIntegrationsContext = () => {
    return useContext(MarketingIntegrationsContext);
};
