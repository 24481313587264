import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMarketingIntegrationsContext } from "./MarketingIntegrationsProvider";

const MetaPixel = () => {
  const location = useLocation();
  const { metaPixelId } = useMarketingIntegrationsContext();
  const initializedPixels = useRef(new Set());

  useEffect(() => {
    // Inject Facebook Pixel script dynamically if not already present
    injectMetaPixelScript();
  }, []);

  useEffect(() => {
    if (!metaPixelId) return;

    initializeMetaPixel(metaPixelId);
  }, [metaPixelId]);

  useEffect(() => {
    if (!metaPixelId) return;

    trackPageView(metaPixelId, { important: true });
  }, [location.pathname, metaPixelId]);

  // Function to inject Facebook Pixel script into the document head
  const injectMetaPixelScript = () => {
    if (document.getElementById("meta-pixel")) return;
    const script = document.createElement("script");
    script.id = "meta-pixel";
    script.innerHTML = `
        !function(f,b,e,v,n,t,s) {
            if (f.fbq) return; n=f.fbq=function(){n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
            if (!f._fbq) f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
            n.queue=[]; t=b.createElement(e); t.async=!0;
            t.src=v; s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    `;

    document.head.appendChild(script);
    console.log("Meta Pixel injected");
  };

  // Function to initialize a Meta Pixel with specific configurations
  const initializeMetaPixel = (id) => {
    if (!id || !window.fbq) return;

    if (initializedPixels.current.has(id)) {
      console.log("Meta Pixel ID already initialized:", id);
      return;
    }

    window.fbq("init", id);
    // window.fbq("track", "PageView");
    initializedPixels.current.add(id);

    console.log("Meta Pixel initialized with ID:", id);
  };

  // Function to track a PageView event with custom parameters
  const trackPageView = (id, params) => {
    if (!id || !window.fbq) return;

    window.fbq("trackSingleCustom", id, "PageView(Filtered)", params);
    console.log("PageView event sent to Meta Pixel ID:", id, "with params:", params);
  };

  return null;
};

export default MetaPixel;
